import React from 'react';
import './App.css';

function App() {
  return (
    <div className="App">
      <h1>Welcome To Enough.me</h1>
      <p>Coming Soon…</p>
      <p>  Enough is just yours.</p>
      <p>  I have enough.</p>
      <p> I don’t have enough.</p> 
      <p> Enough is enough.</p> 
      <p> Sometime enough is good enough .</p> 
      <p> Sometime enough is not good enough.</p> 
      <p> There are no simple answers to enough…</p> 
      <p>  At enough.me, we’ll help you define enough, only enough for you!</p>
    </div>
  );
}

export default App;
